<template>
    <div>
        <el-button plain @click="dialogVisible = true" class="select-video">选择音频书</el-button>

        <div class="select-audio-info" v-for="(item,index) in bookList" :key="item.id">
            <div class="select-audio-info-name">{{item.name}}</div>
            <div class="select-audio-info-option">
                <el-link type="primary" @click="deleteItem(index)">删除</el-link>
            </div>
        </div>

        <div>

        </div>

        <el-dialog title="选择音频" :visible.sync="dialogVisible" width="700px" append-to-body :before-close="handleClose">
            <div slot="title">
                音频书
            </div>

            <div class="audio-content">
                <div class="all-audio">
                    <p class="audio-tag selected">全部音频 <span class="audio-count">({{this.total}})</span> </p>
                </div>

                <div class="audio-list-content">

                    <div class="audio-list" v-loading="loading">
                        <p class="audio-search">
                            <el-input style="width: 180px" v-model="name" clearable placeholder="输入音频名称"></el-input>
                            <el-button plain @click="search" class="margin-l-sm">筛选</el-button>
                        </p>
                        <div v-for="(item,index) in lists" :key="index">
                            <div class="audio-item" @click="selectItem(item.id,item.name)">
                                <span class="select-icon" v-if="bookList.findIndex(v=>v.id == item.id) !== -1"></span>
                                <div class="audio-item-top">
                                    <div class="audio-info">
                                        <p class="audio-info-title">
                                            <el-tooltip effect="dark" :content="item.name" placement="top">
                                                <span class="el-icon-microphone">{{item.name}}</span>
                                            </el-tooltip>
                                        </p>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                            </div>
                        </div>

                        <div class="audio-search">
                            <el-button slot="trigger" size="small" @click="addItem" type="primary">添加音频书</el-button>
                            <div class="page fl-r">
                                <el-pagination small :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
                                    layout="total, prev, pager, next " @size-change="setPageSize"
                                    @current-change="setPage" :total="total">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="select">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import { mapActions } from "vuex";
import tools from "../utils/tools";

export default {
    name: "selecAudio",
    props: {
        userId: Number,
    },
    data() {
        return {
            isSelect: false,
            dialogVisible: false,
            lists: [],
            name: '',
            page: 1,
            pageSize: 10,
            total: 0,
            loading: true,
            uploading: false,
            bookList: []
        }
    },
    methods: {
        ...mapActions('hearBook', ['selectAudioBooksBooks']),
        select() {
            if (this.selectId == 0) {
                this.$message.error('请选择批注');
                return
            }
            this.dialogVisible = false
            this.$emit('onSelect', this.bookList)
        },
        handleClose(done) {
            done();
        },
        setPageSize(pageSize) {
            this.pageSize = pageSize
            this.getList()
        },
        setPage(page) {
            this.page = page
            this.getList()
        },
        getList() {
            console.log(this.userId);
            this.loading = true
            let data = {
                userId: this.userId,
                page: this.page,
                pageSize: this.pageSize,
            }
            if (this.name != '') {
                data.name = this.name
            }
            this.selectAudioBooksBooks(data).then(res => {
                this.lists = res.data.list
                this.total = res.data.total
                this.loading = false
            })
        },
        search() {
            this.page = 1
            this.getList()
        },
        reelectItem() {
            this.dialogVisible = true
        },
        deleteItem(index) {
            this.$emit('onDelete', index)
        },
        addItem() {
            this.$router.push('/audioBooks/add')
        },
        selectItem(id, name) {
            let index = this.bookList.findIndex(v => v.id == id)
            if (index != -1) {
                this.bookList.splice(index,1)
            } else {
                this.bookList.push({ id, name })
            }

        }
    },
    mounted() {
        this.getList()
    }
}
</script>

<style>
.select-audio-info {
    padding: 20px;
    background-color: #fafafa;
    width: 160px;
}

.select-audio-info-name {
    width: 100%;
    color: #333;
    line-height: 20px;
    font-size: 12px;
    word-break: break-all
}

.select-audio-info-option {}

.el-divider__text,
.el-link {
    font-size: 12px;
}

.el-dialog__header {
    padding: 20px;
}

.el-dialog__body {
    padding: 0
}

.audio-content {
    height: 528px;
    border-bottom: 1px solid #ebebeb;
    border-top: 1px solid #ebebeb;
}

.all-audio {
    padding: 20px 10px;
    float: left;
    height: 490px;
    overflow-y: scroll;
}

.selected {
    background: #f7f7f7;
}

.audio-tag {
    height: 32px;
    color: #333;
    font-size: 12px;
    padding: 0 10px;
    line-height: 32px;
    cursor: pointer;
    color: #999;
}

.audio-count {
    margin-left: 5px;
    font-size: 12px;
}

.audio-search {
    width: 100%;
    flex-grow: 2;
    padding-bottom: 10px
}

.audio-list-content {}

.audio-list {
    padding: 20px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.el-card__body {
    padding: 10px
}

.select-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 30px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAeCAYAAABNChwpAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAadJREFUSInF1z1PFEEYAOAHEBIbf8FBQiF/gQIVExYj8Y6Y0HhGqSwEWwu1l2gvhsRGYiRobEDjBxhjZaGUYEzESKWVRqMFCeBZDAYT9nJ7y5J5y5mdeZ/dmZ13t8Vk7RquixStmMAl/IkFgNsYxWYsANzHCNZjAWAep/ArFgBeIcH3IhOd6OTnhWwAeIt+fC0ieVLiRYXLb7IDYBlH8XkvyQc7WRzmzAJ33jcHgE84gpShO/G8zLme3e0DJRYqVBd5sJo+thEAvuAYlupdMLfGvYTT3TttSYmXwyH57Mf6k7eYrGUwgEOYw/G0zpNdPCvT+4jfG6xUw2Ovd+d5AHAQD1FO60xKYb2h8pQna40nbBYA7ZhGNa3z7GE62rj7IdtkeQDQhlu4mGfw/5FlE6bFFsZxMxYAariCq7EA/+IGxuQs50UAYArnsRELADNylPMiAfAYQ5oo50UD4DUG8C0WAN7JWM73CwAr6NOgnO8nwHbyvm1MFABhGfqFr6woAMKGTIQNGgVAeDWHhFc1CoBwSI0I/yBRAITjelQ4vh2IACAUrjH8+Av5AmCiEP+iwQAAAABJRU5ErkJggg==);
}

.audio-item {
    width: 260px;
    height: 72px;
    margin-bottom: 10px;
    border: 1px solid #ebebeb;
    cursor: pointer;
    position: relative;
    line-height: 1;
}

.audio-info {
    float: left;
    margin-left: 10px
}

.audio-item-top {
    padding: 12px 10px 12px 12px;
}

.audio-info-title {
    color: #333;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 20px;
    width: 170px;
    height: 20px;
    overflow: hidden;
}

.audio-info-size {
    color: #999;
    font-size: 12px;
}

.audio-option {
    height: 36px;
    background: #fafafa;
    border-top: 1px solid #ebebeb;

}

.clear {
    clear: both;
}
</style>
